<div class="relative">
	<ngx-intl-tel-input
			class="w-100 d-block"
			cssClass="form-control"
			[cssClass]="{ 'form-control' : 1, 
			'is-invalid': 
				phoneControl.invalid && 
				(phoneControl.touched || phoneControl.dirty) 
			}"
			[preferredCountries]="preferredCountries"
			[enableAutoCountrySelect]="true"
			[enablePlaceholder]="true"
			[customPlaceholder]="to.placeholder"
			[searchCountryFlag]="true"
			[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
			[selectFirstCountry]="false"
			[selectedCountryISO]="CountryISO.India"
			[maxLength]="15"
			[phoneValidation]="true"
			[separateDialCode]="separateDialCode"
			[numberFormat]="numberFormat"
			[formControl]="phoneControl"
			(ngModelChange)="onPhoneChange($event)"
	></ngx-intl-tel-input>
</div>