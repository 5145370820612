import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-emoji',
  templateUrl: './formly-field-emoji.component.html',
  styleUrls: ['./formly-field-emoji.component.scss']
})
export class FormlyFieldEmojiComponent extends FieldType implements OnInit {

  constructor() { super(); }

  ngOnInit(): void {
  }
  onClick(event: any) {
    if (this.to.onClick) {
      this.to.onClick(event);
    }
  }
}
