<p class="mb-1 fs-5" *ngIf="to?.attributes?.filterTitle">{{to?.attributes?.filterTitle}}</p>
<div [ngClass]="{'border border-1 border-dark py-2 px-2 mb-2 rounded-3 ':to?.attributes?.sidebarclass==='sidebarRadioFilter'}" class="style-left">
    <div *ngFor="let option of to.options | selectOptions: field | async; let i = index" class="form-check"
        [class.form-check-inline]="to.formCheck === 'inline'">
        <input type="radio" [id]="id + '_' + i" class="form-check-input" [ngClass]="to?.attributes?.inputClassName" [name]="field.name || id"
            [class.is-invalid]="showError" [attr.value]="option.value" [value]="option.value"
            [formControl]="formControl" [formlyAttributes]="field"
            [attr.disabled]="option.disabled || formControl.disabled ? true : null" />
            <label class="form-check-label" [ngClass]="to?.attributes?.labelClassName" [class.text-success]="checkedValues && checkedValues?.includes(option?.value)" [for]="id + '_' + i">
                {{ option.label }}
                <ng-container *ngIf="checkedValues && checkedValues?.includes(option?.value)">
                    <i class="bi bi-check-circle-fill"></i>
                </ng-container>
            </label>
    </div>
    
</div>