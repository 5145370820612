import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-currency',
  templateUrl: './formly-currency.component.html',
  styleUrls: ['./formly-currency.component.scss']
})
export class FormlyCurrencyComponent extends FieldType implements OnInit {
  commaSeperatedValue:string="";
  ngOnInit(): void {
    if(this.field?.formControl?.value){
      this.amountEntered(this.field?.formControl?.value);
      this.field?.formControl?.setValue(this.commaSeperatedValue);
    }
  }

  amountEntered(value:any){
    this.commaSeperatedValue = (Number((value).replaceAll(",", ""))).toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      currency: 'INR'
    }).replace("$", "").split(".")[0];
  }

}
