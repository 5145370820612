import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ApiHelperService } from '../../services/api-helper.service';
import { MediaService } from '../../services/media.service';
import { PassDataToEditLoanAppService } from './pass-data-to-edit-loan-app.service';

@Component({
  selector: 'apnst-formly-multi-field-file',
  templateUrl: './formly-multi-field-file.component.html',
  styleUrls: ['./formly-multi-field-file.component.scss']
})
export class FormlyMultiFieldFileComponent extends FieldType {
  mimeTypes = {
    "files" : {
      type: [".jpg", ".jpeg", ".png", ".gif", ".pdf", ".mp4"],
      mime: ["image/jpg","image/jpeg", "image/png", "image/gif", "application/pdf", "video/mp4"],
    },
    "document":{
      types: [".pdf"],
      mime: ["application/pdf"]
    },
    "image": {
      types: [".jpg",".png",".gif"],
      mime: ["image/jpg","image/jpeg","image/png", "image/gif"]
    },
    "video":{
      type: [".mp4"],
      mime: ["video/mp4"]
    }
  }
  progress:number = 0;
  selectedFiles: any=[];

  @ViewChild("fileInput") fileInputRef: ElementRef<HTMLInputElement>
  constructor(
    private mediaService: MediaService,
    private cd: ChangeDetectorRef,
    private apiHelperService: ApiHelperService,
    private passDataToEditLoanAppEvent:PassDataToEditLoanAppService){
    super();
  }
  get type() {
    return this.field.type;
  }

  uploadMedia(fileList:FileList){
    const file = fileList.item(0);
    if(fileList.length>0){
        for(let i=0;i<fileList.length;i++){
            const file = fileList.item(i);

            if(!this.mimeTypes["files"]['mime'].includes(file.type)){
                this.fileInputRef.nativeElement.value = "";
                this.apiHelperService.openSnackBar("Please upload valid file");
                return;
              }

              if( file.size > 5*1024*1024){
                this.apiHelperService.openSnackBar("Please upload file of size less than 5mb");
                this.fileInputRef.nativeElement.value = "";
                return;
              }

              this.mediaService.uploadMedia(file).subscribe((data: any)=>{
                if(data && data.progress){
                  this.progress = data.progress;
                }
                if(data && data.body){

                    this.selectedFiles.push(data.body[0]["Location"]);
                    this.formControl.setValue(this.selectedFiles);
                
                    this.fileInputRef.nativeElement.value = "";
                    this.progress = 0;
                }
                this.formControl.markAsTouched();
                this.cd.detectChanges();
              });

        }
        
    }

  }

  removeImage(){
    this.formControl.setValue(null);
    this.selectedFiles = [];
  }

  isImage(url: string){
      var arr = [ "jpeg", "jpg", "gif", "png"];
      var ext = url.substring(url.lastIndexOf(".")+1);
      if(arr.includes(ext)){
        return true;
      } else {
        return false;
      }
  }
  downloadLoanDocumentsByType(documentType:string) {

    this.passDataToEditLoanAppEvent.passDataToEditLoanAppEvent.emit(documentType);

    // this.spinner.show();
    // this.apiHelperService.downloadZip(`loan-applications/${this.loanId}`, "sixmonthsbankstatements").pipe(map((res: any) => {
    //   return b64ToBlob(res, "application/zip");
    // })).subscribe(data => {
    //   fileSaver.saveAs(data, `documents-${this.loanId}.zip`);
    //   this.spinner.hide();
    // }, error => {
    //   this.spinner.hide();
    // })
  }
}
