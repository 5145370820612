<div class="container">
    <div class="d-flex justify-content-between align-items-center mb-0 emojiStyle">
      <div *ngFor="let item of to?.options">
        <div class="card card-bg-custom px-2 me-3" (click)="onClick(item)" style="cursor:'pointer'" *ngIf="item?.value!==0; else skipBlock">
          <p class="mb-0 fs-2"> {{ item?.emoji }} </p>
          <p class="mb-0 text-primary-custom text-center"> {{ item?.label }} </p>
        </div>
  
        <ng-template #skipBlock>
          <div class="skipBtn" (click)="onClick(item)">
            <p class="text-primary-custom"> {{ item?.label }} </p>
          </div>
        </ng-template>
      </div>
    </div>
  </div>