import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Required } from '../../decorators/decorator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { ApiHelperService } from '../../services/api-helper.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';

@Component({
  selector: 'apnst-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @Input() @Required columnDefs: any[];
  @Input() @Required dataModel;
  @Input() @Required dataParams: any;
  @Input() dataProcessor: (data: any) => object;
  public defaultConfig = {
    filter: true,
    pagination: true
  }
  row: any;
  selection = new SelectionModel<any>(true, []);
  public filterText: string;
  public pageSize = 25;
  public pageIndex = 0;
  public totalRecords = 0;
  public pageSizeOptions: number[] = [25, 50, 100];
  public displayedColumns: any[] = [];
  public dataSource: any;
  public data: any;
  loading: boolean = false;
  @Input() options: any = {};
  public filter: any = {};
  public filterBackup: any;
  constructor(
    private service: ApiHelperService,
    public snackBar: MatSnackBar,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.filter = Object.assign(this.filter, this.dataParams);
    this.filterBackup = Object.assign({}, this.filter);
    this.defaultConfig = Object.assign(this.defaultConfig, this.options);
    this.service.paginator({
      pageSize: this.pageSize,
      pageIndex: 0
    });

    if (this.options.pageSize) {
      this.pageSize = this.options.pageSize;
    }

    this.paginationReset();
    this.prepareColumns();
  }

  drop(event: CdkDragDrop<string[]>) {
    debounceTime(400),
      distinctUntilChanged()
    let cols = moveItemInArray(this.columnDefs, event.previousIndex, event.currentIndex);
    this.prepareColumns(true);
  }
  paginationReset() {
    debounceTime(400),
      distinctUntilChanged()
    this.getCount();
  }

  prepareColumns(modified?: boolean) {
    debounceTime(400),
      distinctUntilChanged()
    this.columnDefs = this.columnDefs.filter(item => {
      if (typeof item.condition == 'function') {
        return item.condition(item, this);
      } else if (typeof item.condition == 'boolean') {
        return item.condition;
      }
      if (!item.field) item.field = 'manage';
      item.isFilterAvailable = typeof item.isFilter !== 'undefined';
      item.isSortAvailable = typeof item.isSort !== 'undefined';

      if (typeof item.hasPermission === 'string') {

      }
      return typeof item.hasPermission === 'undefined' || item.hasPermission;
    });
    this.displayedColumns = this.columnDefs.map(col => col.field);


  }
  changePage(e: MatPaginator) {
    this.service.paginator(e);
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.selection.clear();
    this.paginationReset();

  }

  applyDataProcessor(data) {
    let newData = data;
    this.dataSource = undefined;
    if (this.dataProcessor && typeof this.dataProcessor === 'function') {
      newData = this.dataProcessor(this.data);
      if (!newData) { throw new Error('dataProcessor() has not returned proper json object.'); }
    }
    this.dataSource = [...newData];
  }
  getCount() {
    this.service.getCount(`${this.dataModel}/count`, this.filter).subscribe((data: any) => {
      debounceTime(400),
        distinctUntilChanged(),
        this.totalRecords = data.count

      if (this.totalRecords < this.pageSize) {
        this.service.paginator({
          pageSize: this.pageSize,
          pageIndex: 0
        });
      }
      else {
        this.service.paginator({
          pageSize: this.pageSize,
          pageIndex: this.pageIndex,
        });
      }
    });
    this.getData();
  }

  getData() {
    this.loading = true;
    this.service.getData(this.dataModel, this.filter, true).then((data: any) => {
      this.loading = false;
      debounceTime(400),
        distinctUntilChanged(),
        this.data = data.map(item => {
          let newitem: any = this.service.flatten(item);
          newitem.origionalItem = Object.assign({}, item);
          return newitem;
        });
      this.applyDataProcessor(this.data);
    }).catch((err) => {
      this.loading = false;
      let message = err.error?.error ? err.error?.error?.message : 'Unknown Error';
      this.service.openSnackBar(message, { duration: 5000 });
    });
  }

  sortPagination(s?) {
    if (!this.filter.order) {
      this.filter.order = {};
    }
    this.filter.order = s ? `${s.active} ${s.direction}` : undefined;
    this.getData();
  }
  refreshDataTable() {
    this.filter = Object.assign(this.filter, this.dataParams);

    this.paginationReset();

  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  filterChanges(andList?: any[]) {

    this.filter = (andList.length) ?
      { order: this.filterBackup.order, where: { ...this.filterBackup.where, and: andList }, include: this.filterBackup.include } :
      { order: this.filterBackup.order, where: { ...this.filterBackup.where }, include: this.filterBackup.include };
    this.dataParams = Object.assign(this.dataParams, this.filter)
    this.paginationReset();
  }


  actionClick(row, btn) {
    if (btn.type === 'link') {
      if (btn?.onClick) {
        btn.onClick(row);
      }
    }
  }

}