import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { ApiHelperService } from "projects/apna-lib/src/lib/services/api-helper.service";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
    selector: 'fa-async-image',
    template: `<img [src]="signedUrl | async" [ngClass]="styleClass" [width]="widthClass"  />`
})
export class AsyncImageComponent implements OnInit, OnChanges, OnDestroy {
    @Input()
    public src: string;
    @Input()
    public styleClass: string = "";
    @Input()
    public defaultUrl?: string = "";
    @Input()
    public alt?: string = "";
    @Input()
    public widthClass?: string = "";
    public subject = new BehaviorSubject("")
    public signedUrl: Observable<string> = new Observable<string>();

    constructor(private service: ApiHelperService) { }
    ngOnInit(): void {
        this.signedUrl = this.subject.asObservable();
    }

    ngOnDestroy(): void {
        if (this.subject) {
            this.subject.unsubscribe();
        }
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes?.src?.currentValue && changes.src.previousValue !== changes.src.currentValue) {
            if (changes.src.currentValue) {
                const a = changes.src.currentValue.split("/");
                const [folderName, fileName] = a.slice(a.length - 2);
                this.service.generateSignedUrl(folderName, fileName).subscribe(({ signedUrl }) => {
                    this.subject.next(signedUrl);
                });
            } else {
                this.subject.next(this.defaultUrl);
            }
        }
    }

}