import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'apnst-formly-field-button',
  templateUrl: './formly-field-button.component.html',
  styleUrls: ['./formly-field-button.component.css']
})
export class FormlyFieldButtonComponent extends FieldType {
  onClick(event: any) {
    if (this.to.onClick) {
      this.to.onClick(event);
    }
  }
}
