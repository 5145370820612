import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { from, Observable, of, Subject } from 'rxjs';
import { map, startWith, switchMap, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiHelperService } from '../../services/api-helper.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
export interface AutoCompleteOptions {
  name: string;
  value: string;
  fields: string[];
  dataModel: string;
  multiple?: boolean;
  isEditable?: boolean;
  disabled?: boolean;
  onSelect?: (item: any) => void;
  dataProcessor?: (item: any) => any;
  showTopRecords?: boolean;
  defaultValue?: string;
  dataParams?: {
    order?: string;
    where?: any;
    fields?: string[];
    limit?: number;
    aggregate?: boolean
  }
}
@Component({
  selector: 'apnst-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() placeholder: string;

  @Input() control: UntypedFormControl = new UntypedFormControl(null);

  @Input() autoCompleteOptions: AutoCompleteOptions;

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  searchControl = new UntypedFormControl();

  autoCompleteData: Observable<any>;
  latestData: any[] = [];
  allData: any[] = [];

  selectedItem: any;
  selectedWholeItem: any;
  selectedItems: any[] = [];
  selectedWholeItems: any[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private apiHelperService: ApiHelperService) {
  }

  ngOnInit() {
    if(this.autoCompleteOptions?.defaultValue){
      this.searchControl.setValue(this.autoCompleteOptions?.defaultValue);
      this.initAutocomplete();
    }
    else{
      this.initializeData();
      this.initAutocomplete();
    }
  }

  ngAfterViewInit() {
  }

  ngOnDestroy(): void {
  }

  initAutocomplete() {
    //(this.searchControl.valueChanges)
    this.autoCompleteData = this.searchControl.valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        startWith(''),
        switchMap(
          this.bringData.bind(this)
        ),
        map(
          this.transformData.bind(this)
        )
      )
  }
  displayFn(itemValue: any) {
    if (itemValue) {
      //(itemValue)
      let item = this.latestData.find(single => single.value == itemValue);
      if (item) {
        return item.label;
      } else {
        return this.searchControl.value || "";
      }
    } else {
      return this.searchControl.value || "";
    }
  }
  optionSelected(ev: MatAutocompleteSelectedEvent) {

    //setControl
    if (this.autoCompleteOptions?.multiple) {
      // Resetting The Search Control for new Input
      this.searchControl.reset(null);
      if (this.control.value) {
        let values = this.control.value;
        values.push(ev.option.value);
        this.control.setValue(values);
      } else {
        this.control.setValue([ev.option.value]);
      }
    } else {
      this.control.setValue(ev.option.value);
    }
    // Finding And Sending Outside
    this.selectedItem = this.latestData.find(single => single.value == ev.option.value);
    this.selectedWholeItem = this.allData.find(single => single[this.autoCompleteOptions.value] == ev.option.value);
    if (this.autoCompleteOptions?.multiple) {
      this.selectedItems.push({ ...this.selectedItem });
      this.selectedWholeItems.push({ ...this.selectedWholeItem });
      if (this.autoCompleteOptions.onSelect) {
        this.autoCompleteOptions.onSelect(this.selectedWholeItems);
      }
    } else {
      if (this.autoCompleteOptions.onSelect) {
        this.autoCompleteOptions.onSelect({ ...this.selectedWholeItem });
      }
    }
  }
  removeItem(item: any) {
    let itemIndex = this.selectedItems.findIndex(single => {
      return single.value === item.value;
    });
    if (itemIndex > -1) {
      this.selectedItems.splice(itemIndex, 1);
      this.selectedWholeItems.splice(itemIndex, 1);
      let values = this.control.value.splice(itemIndex, 1);
      this.control.setValue(values);
    }
  }

  bringData(searchTerm: string) {
    if (searchTerm) {
      if (this.autoCompleteOptions.dataParams.aggregate) {
        if (!this.autoCompleteOptions.dataParams.where) {
          return this.apiHelperService.searchData(this.autoCompleteOptions.dataModel, searchTerm)
        }
        else {
          this.autoCompleteOptions.dataParams
          let filterObj = { where: { ...this.autoCompleteOptions.dataParams?.where } }
          return this.apiHelperService.searchData(this.autoCompleteOptions.dataModel, searchTerm, filterObj)
        }
      }
      else {
        if (!this.autoCompleteOptions.dataParams.where) {
          this.autoCompleteOptions.dataParams.where = {};
        }
        this.autoCompleteOptions.dataParams.where['or'] = this.autoCompleteOptions.fields.map((single: any) => {
          return { [single]: { like: searchTerm, options: 'i' } };
        });

        this.autoCompleteOptions.dataParams.where['or'].push({ name: { like: 'other', options: 'i' } });
        let filterObj = {...this.autoCompleteOptions.dataParams};
        filterObj["limit"] = 50;
        
        return this.apiHelperService.getData(this.autoCompleteOptions.dataModel, filterObj);
      }
    }


    else if (this.autoCompleteOptions.dataParams.where?.or) {
      delete this.autoCompleteOptions.dataParams.where['or'];
    }
    else if (this.autoCompleteOptions.showTopRecords) {
      let filterObj = {...this.autoCompleteOptions.dataParams};
      filterObj["limit"] = 50;
      delete filterObj?.aggregate;
      return this.apiHelperService.getData(this.autoCompleteOptions.dataModel, filterObj);
    }

    return of([]);
  }

  transformData(data: any) {
    this.allData = data;
    this.latestData = this.convertingToLabelValue(data);
    if (typeof this.autoCompleteOptions.dataProcessor == 'function') {
      let processorData = this.autoCompleteOptions.dataProcessor(this.allData);
      if (!processorData) throw new Error('dataProcessor() has not returned proper json object.');
      this.latestData = processorData;
    }
    return this.latestData;
  }

  convertingToLabelValue(data: any) {
    return data.map((single: any) => {
      let label = this.autoCompleteOptions.fields.reduce((previousValue: string, currentValue: string) => {
        previousValue += " " + single[currentValue];

        return previousValue;
      }, "")

      return { label: label.trim(), value: single[this.autoCompleteOptions.value] }
    })
  }

  initializeData() {

    if (this.autoCompleteOptions?.disabled) {
      this.searchControl.disable();
    }
    if (this.control.value) {
      if (this.autoCompleteOptions?.multiple) {
        from(this.apiHelperService.getData(this.autoCompleteOptions.dataModel, {
          where: {
            [this.autoCompleteOptions.value]: { inq: this.control.value }
          }
        })).pipe(map(this.transformData.bind(this))).subscribe((selectedItems: any) => {
          //(this.selectedItems)
          this.selectedItems = selectedItems.sort((a, b) => this.control.value.indexOf(a.value) - this.control.value.indexOf(b.value));
        })
      }
      else {

        if (this.autoCompleteOptions.dataParams.aggregate) {
          if (!this.autoCompleteOptions.dataParams.where) {
            from(this.apiHelperService.searchData(this.autoCompleteOptions.dataModel, this.control.value)).pipe(map(this.transformData.bind(this))).subscribe((selectedItems: any) => {
              let itemvalue: any
              for (const selected of selectedItems) {
                if (selected.value === this.control.value) {
                  itemvalue = this.control.value
                }
              }
              this.searchControl.setValue(itemvalue);
            })
          }
          else if (this.autoCompleteOptions.dataParams?.where) {
            this.autoCompleteOptions.dataParams
            let filterObj = { where: { ...this.autoCompleteOptions.dataParams?.where } }
            from(this.apiHelperService.searchData(this.autoCompleteOptions.dataModel, this.control.value, filterObj)).pipe(map(this.transformData.bind(this))).subscribe((selectedItems: any) => {
              let itemvalue: any
              for (const selected of selectedItems) {
                if (selected.value === this.control.value) {
                  itemvalue = this.control.value
                }
              }
              this.searchControl.setValue(itemvalue);

            })
          }
        }
        else {
          from(this.apiHelperService.getData(this.autoCompleteOptions.dataModel, {
            where: {
              [this.autoCompleteOptions.value]: this.control.value
            }
          })).pipe(map(this.transformData.bind(this))).subscribe((selectedItems: any) => {
            let itemvalue: any
            for (const selected of selectedItems) {
              if (selected.value === this.control.value) {
                //(selected, "254")
                itemvalue = this.control.value
              }
            }
            this.searchControl.setValue(itemvalue);
          })
        }
      }
    }

  }

  checkOption() {
    if (this.autoCompleteOptions.disabled) {
      this.searchControl.disable();
      return;
    }
    if (this.autoCompleteOptions.isEditable) {
      if(!this.control.value){
        this.control.setValue(this.searchControl.value || null);
      }
      return;
    }
    setTimeout(() => {
      if (!this.control.value) {
        this.searchControl.reset(null);
      }
    }, 500);
  }
}

