<div *ngIf="formControl.value" class="card rounded-2 mb-4 bg-transparent border border-0" title="Hold Ctrl(PC) or Command(Mac) key to select multiple files">
  <div class="card-body position-relative d-flex flex-column align-items-center">
    <p class="card-text text-center  mb-0">{{ to.label }} <span
        *ngIf="to.required && to.hideRequiredMarker !== true"  class="text-danger" aria-hidden="true">*</span></p>
        <div class="btn-group" role="group" aria-label="File Button Toolbar">
          <ng-container *ngIf="!to?.hideDeleteDownloadButton">
          <button type="button" class="btn btn-link" (click)="removeImage()"><i class="bi bi-trash"></i> </button>
          <p class="btn btn-link mb-0" (click)="downloadLoanDocumentsByType(to?.attributes?.dataname)" ><i class="bi bi-download"></i></p>
        </ng-container>    
        </div>
        <div class="img-wrapper">
          <ng-container *ngIf="type === 'files'">
              <ng-container *ngFor="let value of formControl.value">
                   <ng-container *ngIf="isImage(value); else fileTemplate">
                      <img [src]="value" class="uploaded-image" />
                  </ng-container>
              </ng-container>
            <ng-template #fileTemplate>
              <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1682432634780pdf-1.png" class="uploaded-image" />
            </ng-template>
          </ng-container>
          <img src="https://foreign-admits-s3-bucket-public.s3.ap-south-1.amazonaws.com/website-cms/1682432634780pdf-1.png" *ngIf="type ==='document'" class="uploaded-image" />
          <img [src]="formControl.value" *ngIf="type ==='image'" class="uploaded-image" />
        </div>
  </div>
</div>
<!-- NEW CARD IF DATA NOT PRESENT -->
<p class="card-text text-start  mb-0" *ngIf="!formControl.value">{{ to.label }} <span *ngIf="to.required && to.hideRequiredMarker !== true "
  class="text-danger" aria-hidden="true">*</span></p>
<div class="card position-relative rounded-2" [class.border-danger]="showError" apnstDragAndDrop
  (fileDropped)="uploadMedia($event)" (click)="fileInput.click()" *ngIf="!formControl.value">

    <div class="card-body py-2 d-flex justify-content-center align-items-center">
      <p *ngIf="to?.placeholder" class="text-muted mb-0 me-3">{{to?.placeholder}}</p><i class="bi bi-upload fs-5"></i>
      <!-- <span class="mt-1">Drag and Drop</span>
      <span class="mt-1 btn-link">Browse</span> -->
    </div>
</div>
<input type="file" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError" #fileInput
  (change)="uploadMedia(fileInput.files)" [hidden]="true" />
<div class="progress" *ngIf="progress">
  <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="{width: progress ? progress + '%': '0%'}"
    [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
</div>