<div [class.modal-content]="isModel">
    <div class="preloader-container" *ngIf="loading">
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="isModel" class="modal-header">
        <h3 class="modal-title">{{ modalTitle }}</h3>
        <button class="btn-close" matDialogClose></button>
    </div>
    <div *ngIf="!loading && form" [class.modal-body]="isModel">
        <form [formGroup]="form" (ngSubmit)="onFormSubmit(model)" (valueChanges)="onChanges($event)" autocomplete="off" #formRef>
            <formly-form [form]="form" [fields]="formLayout" [model]="model"></formly-form>
        </form>
    </div>
    <div *ngIf="isModel" class="modal-footer">
        <button type="submit" class="ms-auto btn btn-primary" (click)="submitForm($event)">Save</button>
        <button type="submit" class="btn btn-outline-danger" matDialogClose> Cancel </button>
    </div>
</div>