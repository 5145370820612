import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServicesModule } from '../services/services.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { BaseSchemaFormComponent } from './base-schema-form/base-schema-form.component';
import { NavService } from '../services/nav.service';
import { RouterModule } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FaFormlyConfig, formlyComponents, } from '../formly-components/formly-inputs';
import { AccessControlDirective } from '../directives/access-control.directive';
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { NgxOtpInputModule } from 'ngx-otp-input';
import { DragAndDropDirective } from '../directives/drag-and-drop.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { SelectOptionsPipe } from '../pipes/select-options.pipe';
import { EditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular"
import { IsUserRoleDirective } from '../directives/is-user-role.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule} from '@angular/cdk/drag-drop';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteModule } from "./autocomplete/autocomplete.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { AsyncImageComponent } from 'projects/foreign-admits/src/app/components/async-image/async-image';
import { DataTableComponent } from './data-table/data-table.component';
@NgModule({
    declarations: [
        BaseSchemaFormComponent,
        ...formlyComponents,
        AccessControlDirective,
        DragAndDropDirective,
        SelectOptionsPipe,
        IsUserRoleDirective,
        AsyncImageComponent,
        DataTableComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        ServicesModule,
        MatFormFieldModule,
        MatTableModule,
        MatButtonModule,
        MatPaginatorModule,
        MatSortModule,
        MatInputModule,
        MatIconModule,
        MatListModule,
        MatToolbarModule,
        MatMenuModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatStepperModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        FormlyModule.forRoot(FaFormlyConfig),
        FormlyBootstrapModule,
        NgxIntlTelInputModule,
        NgxOtpInputModule,
        MatCheckboxModule,
        OverlayModule,
        CdkAccordionModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatSelectModule,
        EditorModule,
        DragDropModule,
        NgbDropdownModule,
        AutocompleteModule,
        NgxSpinnerModule,
        NgbNavModule,
        MatPaginatorModule
        
    ],
    providers: [
        NavService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ],
    exports: [
        BaseSchemaFormComponent,
        AccessControlDirective,
        IsUserRoleDirective,
        AutocompleteModule,
        AsyncImageComponent,
        DataTableComponent
    ]
})
export class ComponentsModule { }
