<div class="d-flex align-items-center justify-content-end">
    <p class="mb-0 me-5">Total Records <strong>{{totalRecords}}</strong></p>
    <p class="mb-0 me-2">{{(pageIndex*pageSize)+1}}-{{(pageSize*(pageIndex+1))}}</p>
    <mat-paginator *ngIf="defaultConfig.pagination" [pageSizeOptions]="pageSizeOptions" (page)="changePage($event)"
        [length]="totalRecords"></mat-paginator>
</div>
<div class="preloader-container text-center" *ngIf="loading">
    <mat-spinner class="mx-auto mt-3"></mat-spinner>
</div>
<div class="container" *ngIf="!loading">
    <table *ngIf="!loading" [fixedLayout]="true" class="bg-white" mat-table [dataSource]="dataSource" matSort>
        <ng-container [matColumnDef]="column.field" *ngFor="let column of columnDefs" [sticky]="column.stickyLeft"
            [stickyEnd]="column.stickyRight">
            <ng-container>
                <!-- Select checkbox for selecting all the records at a time -->
                <th mat-header-cell *matHeaderCellDef [hidden]="column.hidden" [ngStyle]="{'width': column.width}">
                    <ng-container>
                        <span>{{column.title}}</span>
                    </ng-container>
                </th>
            </ng-container>
            <!-- Date displaying in dd/mm/yyyy format from database -->
            <ng-container *ngIf="!column.template && column.pipe == 'date'">

                <td [hidden]="column.hidden" mat-cell *matCellDef="let element"><span [matTooltip]="column.pipeFormat ? (element[column.field] |
                date:column.pipeFormat) : (element[column.field] |date:'dd-MM-yyyy')">{{ column.pipeFormat ?
                        (element[column.field] |
                        date:column.pipeFormat) : (element[column.field] | date:'dd-MM-yyyy') }}</span></td>
            </ng-container>
            <!-- Number displaying on the table if decimal upto 4 decimal points -->
            <ng-container *ngIf="!column.template && column.pipe == 'number'">

                <td [hidden]="column.hidden" mat-cell *matCellDef="let element"><span [matTooltip]="column.pipeFormat ? (element[column.field] |
                number:column.pipeFormat) : (element[column.field] | number:'1.2-4')">{{ column.pipeFormat ?
                        (element[column.field] |
                        number:column.pipeFormat) : (element[column.field] | number:'1.2-4') }}</span></td>
            </ng-container>

            <ng-container *ngIf="!column.template && !column.pipe">
                <td [hidden]="column.hidden" mat-cell *matCellDef="let element">
                    <!-- Select Dropdown -->
                    <ng-container *ngIf="column.isEditable">
                        <ng-container
                            *ngIf="(!column?.conditionForEdit || element[column.field]===column?.conditionForEdit?.value );else isNotEditable">
                            <select style="min-width: 160px;"
                                [disabled]="column.disable ? column.disable(element) : false"
                                class="form-select form-select-sm" [(ngModel)]="element[column.field]"
                                (ngModelChange)="updateRecord(element.id, column.field,element[column.field], element)">
                                <option value="" disabled>Select {{column.title}}</option>
                                <option *ngFor="let single of column?.options | selectOptions | async"
                                    [ngValue]="single.value">
                                    {{single.label}}</option>
                            </select>
                        </ng-container>
                        <ng-template #isNotEditable>
                            <p class="mb-0 text-center " [matTooltip]="element[column?.field]">
                                {{element[column?.field]|titlecase}}</p>
                        </ng-template>
                    </ng-container>
                    <!-- This section is for other options other than isEditable,isAutocomplete,isAutocomppletePartner -->
                    <ng-container
                        *ngIf="!column.isEditable || !column.isAutoComplete || !column.isAutoCompletePartner ||!column.isAutoCompleteUser">
                        <mat-checkbox *ngIf="column.isSelect" (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(element[selectColumnName??'id']) : null"
                            [checked]="selection.isSelected(element[selectColumnName??'id'])">
                        </mat-checkbox>

                        <ng-container *ngIf="column?.options ;else defaultElse">
                            <ng-container *ngIf="column?.options  | selectOptions | async as allOptions">
                                <span [matTooltip]="getOptionLabel(element[column.field], allOptions)"
                                    *ngIf="!column.isEditable">{{getOptionLabel(element[column.field],
                                    allOptions)}}</span>
                            </ng-container>
                        </ng-container>

                        <ng-template #defaultElse>
                            <span [matTooltip]="element[column.field]"
                                *ngIf="!column.isAutoComplete && !column.isAutoCompletePartner && !column.isAutoCompleteUser && !column.isAssignedTo && !column.isTooltipHover">{{element[column.field]??column?.default}}</span>
                        </ng-template>

                    </ng-container>

                </td>
            </ng-container>

            <!-- This section is for Button template -->
            <ng-container *ngIf="column.template">

                <td [hidden]="column.hidden" mat-cell *matCellDef="let element">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <ng-container *ngFor="let btn of column.template">
                            <ng-container *ngIf="btn.hasPermission; else elseButtonTemplate">
                                <button type="button"
                                    [ngClass]="btn.class ? btn.class : 'btn btn-link text-decoration-none'"
                                    [color]="btn.color" (click)="actionClick(element, btn)" *ngIf="btn.hasPermission"
                                    [hidden]="btn.hide ? btn.hide(element): false">
                                    <ng-container *ngIf="btn.icon; else nameTemplate">
                                        <i-feather *ngIf="btn.iconType !=='bootstrap'" [name]="btn.icon"></i-feather>
                                        <i *ngIf="btn.iconType=='bootstrap'" class="bi bi-{{btn.icon}} fs-3"></i>
                                    </ng-container>
                                    <ng-template #nameTemplate>
                                        {{btn.title}}
                                    </ng-template>
                                </button>
                            </ng-container>
                            <ng-template #elseButtonTemplate>
                                <button type="button" *ngIf="btn.hasPermission"
                                    [ngClass]="btn.class ? btn.class : 'btn btn-link text-decoration-none'"
                                    [color]="btn.color" (click)="actionClick(element, btn)" matTooltip="{{btn.title}}"
                                    [hidden]="btn.hide ? btn.hide(element): false">
                                    <ng-container *ngIf="btn.icon; else nameTemplate">
                                        <i-feather *ngIf="btn.iconType!=='bootstrap'" [name]="btn.icon"></i-feather>
                                        <i *ngIf="btn.iconType=='bootstrap'" class="bi bi-{{btn.icon}} fs-3"></i>
                                    </ng-container>
                                    <ng-template #nameTemplate>
                                        {{btn.title}}
                                    </ng-template>
                                </button>
                            </ng-template>
                        </ng-container>
                    </div>
                </td>
            </ng-container>

        </ng-container>
        <!-- This section is for Header sticking and displaying column names -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>