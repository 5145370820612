<div *ngIf="formControl.value" class="card rounded-5 mb-4 bg-primary bg-opacity-10">
  <div class="card-body position-relative d-flex flex-column align-items-center">
    <p class="card-text text-center fw-bold mb-0">{{ to.label }} <span
        *ngIf="to.required && to.hideRequiredMarker !== true" class="text-danger"aria-hidden="true">*</span></p>

    <div class="img-wrapper">
      <ng-container *ngIf="type === 'file'">
        <ng-container *ngIf="isImage(formControl.value); else fileTemplate">

          <img *ngIf="imgLink" [src]="imgLink" class="uploaded-image" />

        </ng-container>
        <ng-template #fileTemplate>
          <img src="/assets/pdf-file.png" class="uploaded-image" />
        </ng-template>
      </ng-container>
      <img src="/assets/pdf-file.png" *ngIf="type ==='document'" class="uploaded-image" />
      <img [src]="formControl.value" *ngIf="type ==='image'" class="uploaded-image" />
    </div>
    <div class="btn-group" role="group" aria-label="File Button Toolbar">
      <button type="button" class="btn btn-link" (click)="removeImage()"><i class="bi bi-trash"></i> </button>
      <ng-container *ngIf="!to.download">
        <a class="btn btn-link" (click)="downloadDocumentByS3Uri(formControl.value)"> <i class="bi bi-download"></i>
        </a>
      </ng-container>

    </div>
  </div>
</div>
<!-- NEW CARD IF DATA NOT PRESENT -->

<div class="card position-relative rounded-5 bg-primary bg-opacity-10" apnstDragAndDrop
  (fileDropped)="uploadMedia($event)" (click)="fileInput.click()" *ngIf="!formControl.value &&!to.isOnboarding">
  <div class="card-body d-flex flex-column align-items-center">
    <p class="card-text text-center fw-bold mb-1">{{ to.label }} <span
        *ngIf="to.required && to.hideRequiredMarker !== true" class="text-danger" aria-hidden="true">*</span></p>
    <img src="assets/upload.svg" alt="upload-file" width="25rem" />
    <span class="mt-1">Drag and Drop</span>
    <span class="button-styling mt-1 btn-link">Browse</span>
  </div>
</div>
<input type="file" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError" #fileInput
  (change)="uploadMedia(fileInput.files)" [hidden]="true" />
<div class="progress" *ngIf="progress">
  <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="{width: progress ? progress + '%': '0%'}"
    [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
</div>

<!-- Onboarding form -->
<div class="card position-relative rounded-5 bg-white bg-opacity-10" apnstDragAndDrop
  (fileDropped)="uploadMedia($event)" (click)="fileInput.click()" *ngIf="!formControl.value &&to.isOnboarding">
  <div class="card-body d-flex justify-content-between align-items-center">
    <p class="card-text text-start text-muted mb-0">{{ to.label }} <span
        *ngIf="to.required && to.hideRequiredMarker !== true"  class="text-danger" aria-hidden="true">*</span></p>
    <img src="assets/upload.svg" alt="upload-file" width="25rem" />
  </div>
</div>
<input type="file" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError" #fileInput
  (change)="uploadMedia(fileInput.files)" [hidden]="true" />
<div class="progress" *ngIf="progress">
  <div class="progress-bar progress-bar-striped" role="progressbar" [ngStyle]="{width: progress ? progress + '%': '0%'}"
    [attr.aria-valuenow]="progress" aria-valuemin="0" aria-valuemax="100"></div>
</div>